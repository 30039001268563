<template>
  <el-container>
  <el-header class="mainheader">
      <div class="bg">
          <el-carousel trigger="click" height="500px">
              <el-carousel-item  key="3">
        <img src="https://ahsy.oss-cn-hangzhou.aliyuncs.com/indexlb2.png" align="bottom"  width="100%" height="100%" alt="">
      </el-carousel-item>
      <el-carousel-item  key="1">
        <img src="https://ahsy.oss-cn-hangzhou.aliyuncs.com/indexlb3.png" align="bottom" width="100%" height="100%"  alt="">
      </el-carousel-item>
    </el-carousel>
      </div>
      <div class="mot">
      <div class="top">
<div>
    <el-row >
  <el-col :span="16" class="logo-main">
     <!-- <el-avatar class="avatar" :size="80" src="https://ahsy.oss-cn-hangzhou.aliyuncs.com/bg/WechatIMG1572.jpeg"></el-avatar> -->
 “AIOT+区块链”茶产业互联网平台
  </el-col>
  <el-col :span="2"><div class="grid-content bg-purple-light">.</div></el-col>
  <el-col :span="8" class="mainlogin">
       <div>
           {{BusinessInfo.qymc}} 
    <span v-if="!BusinessInfo.qymc" @click="tologin">登录</span>
    <el-divider direction="vertical"></el-divider>
    <span v-if="!BusinessInfo.qymc" @click="toregister">注册</span>
    <span v-if="BusinessInfo.qymc" @click="tologinout">退出登陆</span>
    <el-divider direction="vertical"></el-divider>
    <span @click="togongzuo">工作台</span>
  </div>
  </el-col>
</el-row>
</div>
<el-menu :default-active="$router.path" :router="true" active-text-color="#fff" text-color="rgba(255,255,255,1)" class="index-menu" mode="horizontal" @select="handleSelect">
  <el-menu-item index="/index" style="color: rgb(255, 255, 255); border-bottom-color: transparent;">首页</el-menu-item>
  
  <el-menu-item index="" style="color: rgb(255, 255, 255); border-bottom-color: transparent;"><router-link :to="sydaohang" target="_blank" >皖西徽茶品牌保护与防伪系统</router-link> </el-menu-item>
  <el-menu-item :index="czshow" style="color: rgb(255, 255, 255); border-bottom-color: transparent;">徽茶品牌存证与智能合约系统</el-menu-item>
  <el-menu-item index="/gongzhongzhi" style="color: rgb(255, 255, 255); border-bottom-color: transparent;">六安瓜片供应链大数据系统</el-menu-item>
  <el-menu-item index="" style="color: rgb(255, 255, 255); border-bottom-color: transparent;">茶叶加工智能化系统</el-menu-item>
  <el-menu-item index="" style="color: rgb(255, 255, 255); border-bottom-color: transparent;"><a href="http://jgbigdata.wtycms.cn/" target="_blank" >大数据分析</a> </el-menu-item>
  <el-menu-item index="" style="color: rgb(255, 255, 255); border-bottom-color: transparent;"><router-link to="selectsym" target="_blank" >追溯码查询</router-link> </el-menu-item>
  <!-- <el-submenu index="" >
    <template slot="title" >Paas平台</template>
    <el-menu-item index="" style="border-bottom-color: transparent; color: rgb(0,0,0);">开放平台</el-menu-item>
    <el-menu-item index="" style="border-bottom-color: transparent; color: rgb(0,0,0);">数据对接平台</el-menu-item>
    
  </el-submenu> -->
</el-menu>
</div>
</div>
  </el-header>
  <el-main class="mainshow">
    <!-- --------------------政策资讯-------------------- -->
<!-- <el-row type="flex" class="row-bg" justify="center">
  <el-col :span="6" style="text-align:center;font-size:24px;height: 128px;line-height:128px">企业介绍</el-col>
</el-row>
<el-row type="flex" class="row-bg" justify="center" style="height:500px;font-size:20px;">
  <el-col :span="15">
 &emsp;&emsp;金寨县三个农民电子商务有限公司成立于2013年12月，扎根于 中国长寿之乡--金寨县。 公司先后投入资金8000多万元，致力为全国消费者提供优质、特色生态农产品。

<br> &emsp;&emsp;三个农民作为金寨首家新型商业模式的电商企业，是以各类长寿生态农产品及相关徽茶产品的研发、分装及网络品牌营销的现代新型企业，并拥有自身的销售平台，采取全新的线上线下相结合的方式，形成一条从“生产基地+电商平台+直销中心”的农产品产销生态链。公司除拥有自建国家级农民专业合作社及合作的76家种养殖生产基地外,还同2700家农户签订合作协议,并在上海、合肥、常州、厦门等地建立直营店，给全国消费者带去原始生态的大别山农产品。

公司与各类国有企业达成了有关“精准扶贫”、“乡村振兴” 的协议，在城市商业区、高铁候车厅、高速服务区等地段开设扶贫农产品展销窗口。通过窗口宣传革命老区历史文化、拓宽农产品销售市场、支持老区精准扶贫，帮助老区人民早日脱贫致富。目前，公司设立的展销窗口共计吸引游客及客商20000余人次前往金寨参观、投资、考察。相信通过进-步的发展，公司将提供更加多样性和人性化的服务，带动更多农民朋友实现致富增收，把“三个农民”打造成全国知名的农业农村文化品牌。

<br> &emsp;&emsp;公司在电子商务领域做出的努力也受到了政府及业界的一致认可。先后获得了“六安市电子商务示范企业”、“安徽省两化融合示范企业”、“安徽省十佳高成长性电商企业”、“安徽省十大电商品牌”、“安徽省农业产业化龙头企业”等荣誉，同时公司创始人童维 新也先后获得“安徽省劳动模范”、“全国双创之 星”、“全国农村青年致富带头人”、“全国十佳农民”等荣誉。

目前公司运营状况良好，2019年度全年总产值达到1.4亿元，总盈利698万元。
  </el-col>
  
  
 
</el-row> -->
<!-- --------------------政策资讯-------------------- -->
      <!-- --------------------平台介绍---------------------->
<el-row type="flex" class="row-bg" justify="center">
  <el-col :span="6" style="text-align:center;font-size:24px;height: 128px;line-height:128px">平台介绍</el-col>
</el-row>
<el-row type="flex" class="row-bg" justify="center">
    <el-col :span="9" style="margin-left:10px;">
        <video-player class="video-player vjs-custom-skin" 
            ref="videoPlayer" 
            :playsinline="true" 
            :options="playerOptions">
      </video-player>


  </el-col>
  <el-col :span="9" class="info" style="">
   &emsp;&emsp;推出高品质皖西徽茶区块链技术支撑与质量品牌提升项目。本项目的实施，将发挥项目建设单位所在区域的交通区位优势、人才技术集聚优势，以及先进徽茶要素集聚优势，以项目建设为抓手，集聚资金、人才、技术和要素,加快推进区块链技术在徽茶产业链的示范与应用，提升微茶品质监管效率，促进高品质皖西徽茶品牌建设。本项目依托当地资源和基础优势，立足六安，面向安徽省茶产业企业、合作社、茶农和涉茶政府机构提供茶产业区共链技术服务，是一个生态良性循环，没有风险，前景广阔的绿色产业，促进安徽省及周边地区茶产业的发展。
  </el-col>
</el-row>
<!-- --------------------平台介绍-------------------- -->



<el-row type="flex" class="row-bg" justify="center">
  <el-col :span="6" style="text-align:center;font-size:24px;height: 128px;line-height:128px">平台小程序</el-col>
</el-row>
<el-row type="flex" class="row-bg" justify="center">
  <el-col :span="6" style="text-align:center;">
    <img src="../../assets/images/phone1.png" width="300px" alt="">
  </el-col>
  <el-col :span="6" style="margin-left:10px;text-align:center;">
      <div class="download-mot">
  <span class="tips" >移动端</span>
      <div class="download" >
          <img src="../../assets/images/wechat.jpg" width="134" alt="">
          <span>扫描二维码</span>
            <span>微信小程序</span>

      </div>
      </div>
  </el-col>
 
</el-row>
  </el-main>
  <el-footer class="footer" >
<el-row type="flex" class="row-bg" justify="center">
  <el-col :span="6" style="line-height:29px">
      <div>快速入口</div>
      
      
      <div>
 <el-link type="info" href="http://nync.ah.gov.cn/">安徽省农业农村厅</el-link>
      </div>
      <div>
<el-link type="info" href="http://www.aielab.net/ahsy">安徽省农产品追溯平台</el-link>
      </div>
      <div>
<el-link type="info" href="https://www.jiaotu.cc/" >安徽椒图信息科技有限公司</el-link>
      </div>
        
          
           
  </el-col>
  <el-col :span="6" style="line-height:29px">
     
      </el-col>
  <el-col :span="6" style="line-height:29px">
    <div>友情链接</div>
       <div>
          <div>
 <el-link type="info" href="http://nync.ah.gov.cn/">安徽省农业农村厅</el-link>
      </div>
<el-link type="info" href="http://www.aielab.net/ahsy">安徽省农产品追溯平台</el-link>
      </div>
    
     
      <div>
<el-link type="info" href="http://www.aielab.net/">安徽省北斗精准农业信息工程实验室</el-link>
      </div>
    
    </el-col>
</el-row>
<el-row type="flex" class="row-bg" justify="center">
  <el-col :span="6">
      
  </el-col>
  <el-col :span="6"><div class="grid-content bg-purple-light"></div></el-col>
  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
</el-row>
<div style="color: #909399; ">版权所有：安徽椒图信息科技有限公司</div>
<div style="color: #909399;">技术支持：安徽椒图信息科技有限公司、 安徽省北斗精准农业信息工程实验室</div>
  </el-footer>
</el-container>

</template>

<script>
export default {
    data(){
        return{
            sydaohang:"sydaohang",
            czshow:"czshow",
            activeIndex:"/index",
            playerOptions: {
            playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
            autoplay: true, // 如果为true,浏览器准备好时开始回放。
            muted: true, // 默认情况下将会消除任何音频。
            loop: false, // 是否视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
              type: "video/mp4", // 类型
              src: 'https://ahsy.oss-cn-hangzhou.aliyuncs.com/bg/wKgSG15rDO2AL6H-A2o6f98CE6U695.mp4' // url地址
            }],
            poster: '', // 封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true, // 当前时间和持续时间的分隔符
              durationDisplay: true, // 显示持续时间
              remainingTimeDisplay: false, // 是否显示剩余时间功能
              fullscreenToggle: true // 是否显示全屏按钮
            }
          },
            count:0,
            activeName: 'first',
            BusinessInfo:[]
        }
    },
    created(){
        this.getUserInfo();
    },
    methods:{
      toregister(){
        this.$router.push("/register")
      },
        tologinout(){
            window.sessionStorage.clear()
            window.localStorage.clear()
            location.reload();
        },
        async getUserInfo(){
      const {data:res} = await this.$http.post('BusinessInfo')
      this.userinfo =JSON.parse( window.localStorage.getItem('userInfo'))
      if(this.userinfo.groupname!="企业"){
        this.sydaohang="qylist"
        this.czshow="qylist"

      }
      if(res.code==0){
        window.localStorage.setItem('BusinessInfo',JSON.stringify(res.data))
        this.BusinessInfo=res.data

      }
    },
        tologin(){
            this.$router.push("/login")
            // this.$request({
            //   methods:"get",
            //   url:"qyindex",
            //   data:{"page":1,"limit":5}
            //   }).then(res=>{
            //   console.log(res)
            // })
        },
        togongzuo(){
this.$router.push('enterpriseHome')
        },
        handleClick(e){

        },
        handleSelect(e){

        },
        load () {
        // this.count += 1
      }
    }

}
</script>

<style>
.mainshow{
    background-color: #fff;
}
.download-mot{
margin: 80px auto;
}
.download-mot .tips{
    line-height: 60px;
    font-size: 30px;
}
.download{
    width: 218px;
    height: 218px;
    border: 1px solid #dad9e3;
    /* background: #f5f6f9; */
    background-color: #E0E0E0;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    
    
}
.download img{
    margin: 18px 0 9px 0px;
}
.download span{
font-size: 14px;
    color: #6d6c7d;
    text-align: center;
    display: block;
    line-height: 20px;

    }
.mainheader{
    height: 500px!important;
    padding: 0;
    /* background-image: url("../../assets/images/daohangbg2.png"); */
    background-size: 100%;
       
}
.avatar{
  background-color: #fff;
  vertical-align: middle;
}
.info{
    color:#333;
    font-size:20px;
    margin-left:30px;
    line-height: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.el-submenu__title i{
    color: snow;
}
.footer{
    background-image: url("../../assets/images/footer.png");
    background-size: cover;
    height: 200px!important;
    color: seashell;
    padding-top: 30px;
    text-align: center;
}
.mot{
    height: 280px;
background: #4AC29A;  /* fallback for old browsers */
background: -webkit-linear-gradient(to bottom, #4AC29A,rgba(255,255,255,0) );  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to bottom, #197e19,rgba(255,255,255,0) ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.top{
    width: 80%;
    margin: 0 auto;
}
.logo-main{
  margin-top: 1vw;
    height: 4vw;
    line-height: 4vw;
    color: #fff;
    font-size: 2.0vw;
    font-weight: 600;
    align-items: center;
    
}
.mainlogin{
    height:4vw;
    line-height: 4vw;
    color: #fff;
    font-size: 1vw;
    
    text-align: right;
    cursor: pointer;
}
.el-menu{
    background: none;
  
}
.el-submenu__title{
    font-size: 20px;
}

.el-menu.el-menu--horizontal{
border: none;

}
.index-menu .el-menu-item{
font-size: 1vw!important;
}
/* .el-menu-item:hover{
 background: none;
 color: #000;
} */
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
     background: none;
  
}
.bg{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    background-color: seagreen;
    min-height: 500px;
    
}

</style>